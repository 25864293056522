<template>
  <div class="landing-page admin-page">
    <div class="container">
      <AdminHeader />

      <h2 class="heading">Neue Abbuchungen</h2>

      <p class="body-1">
        Mithilfe von dieser Web-Applikation kannst du Gutscheine, die Personen
        von der Redaktion der FHG News erhalten haben, einlösen. Um eine neue
        Abbuchung von einem Gutschein zu tätigen, musst du lediglich den QR-Code
        auf dem vorgezeigten Gutschein scannen oder im Textfeld die ID Nummer
        des Gutscheins eingeben.
      </p>

      <v-form
        class="idForm"
        ref="idForm"
        v-model="valid"
        @submit.prevent="submitIdForm"
        @keyup.enter="submitIdForm"
      >
        <v-text-field
          outlined
          class="idField"
          label="ID Nummer"
          v-model="idNumber"
          :rules="[
            (value) => value.trim().length > 0 || 'Dies ist ein Pflichtfeld',
            (value) =>
              (/^\d+$/.test(value) && value.length <= 14) ||
              'Gib eine 14-stellige Nummer ein',
          ]"
          :error-messages="idInvalidMessage"
          @input="idInvalidMessage = ''"
          counter="14"
          type="number"
          append-icon="qr_code_scanner"
          @click:append="qrCodeScanner"
        ></v-text-field>
        <v-btn elevation="0" class="submitBtn" type="submit" color="primary"
          >Abbuchung hinzufügen</v-btn
        >
      </v-form>
    </div>

    <v-dialog v-model="error.active" persistent max-width="500">
      <v-card>
        <template v-if="error.code === 'insufficientDebitPermission'">
          <v-card-title style="word-break: break-word;">
            Unzureichende Berechtigung
          </v-card-title>
          <v-card-text>
            Du hast nicht die nötige Berechtigung um bei diesem Gutschein eine
            Abbuchung hinzuzufügen, weil es beispielsweise Teil einer Kampagne
            ist, für die du keine Berechtigungen besitzt.<br />
            Falls dies ein Fehler ist, versuche es später erneut oder wende dich
            an den Support.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="error = { active: false, code: '' }"
              exact
              to="/d"
              replace
              >OK</v-btn
            >
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="qrScanner.active"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      content-class="qrScannerDialog"
    >
      <div class="wrapper">
        <!-- <video class="backgroundVideo" id="preview" ref="bgVideo"></video> -->
        <qrcode-stream
          class="backgroundVideo"
          @decode="onDecode"
          @init="onInit"
          :torch="qrScanner.flash"
        ></qrcode-stream>
        <v-btn icon dark class="closeBtn" @click="closeQrScanner">
          <v-icon>close</v-icon>
        </v-btn>
        <v-btn
          icon
          dark
          class="flashBtn"
          v-if="qrScanner.hasFlash"
          @click="
            qrScanner.hasFlash ? (qrScanner.flash = !qrScanner.flash) : null
          "
        >
          <v-icon v-if="!!qrScanner.flash">flash_off</v-icon>
          <v-icon v-else>flash_on</v-icon>
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import AdminHeader from '@/components/AdminHeader';
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  name: 'AdminNewDebitLanding',
  components: {
    AdminHeader,
    QrcodeStream,
  },
  data() {
    return {
      valid: false,
      idNumber: '',
      idInvalidMessage: '',
      error: { active: false, code: '' },
      qrScanner: {
        active: false,
        hasFlash: false,
        flash: false,
      },
    };
  },
  watch: {
    '$route.query.error': {
      handler(error) {
        if (error === 'insufficientDebitPermission') {
          this.error = {
            active: true,
            code: 'insufficientDebitPermission',
          };
        }
      },
      immediate: true,
    },
  },
  methods: {
    submitIdForm() {
      let idForm = this.$refs.idForm;
      if (!idForm) return;

      let valid = idForm.validate();
      if (!valid) return;

      if (this.idNumber.length !== 14)
        return (this.idInvalidMessage = 'ID Nummer muss 14-stellig sein');

      this.$router.push({
        path: `/d/${this.idNumber}`,
      });
    },
    onDecode(result) {
      console.log(result);

      let url;
      try {
        url = new URL(result);
      } catch (_) {
        return;
      }
      if (url.protocol !== 'http:' && url.protocol !== 'https:') return;

      if (
        ![
          'coupons.fhgnews.de',
          'fhgnews-coupons.web.app',
          'fhgnews-coupons.firebaseapp.com',
        ].includes(url.host)
      )
        return;

      let id = url.pathname;
      this.$router.push({ path: id });
    },
    async onInit(promise) {
      const { capabilities } = await promise;

      this.qrScanner.hasFlash = !!capabilities.torch;
    },
    async qrCodeScanner() {
      this.qrScanner.active = true;
    },
    closeQrScanner() {
      this.qrScanner.flash = false;
      this.qrScanner.active = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.landing-page {
  padding: 32px 16px 0;
  font-size: 14px;
  min-height: 100vh;
  background-color: #fafafa;

  .container {
    padding: 0;
    max-width: 800px;
    display: block;
    margin: 0 auto;

    p {
      margin: 0;
    }

    .heading {
      font-size: 18px;
      font-weight: 500;
      color: #000000;
      margin: 0 0 12px 0;
    }

    .idForm {
      margin: 32px 24px;

      .idField {
        &::v-deep input {
          letter-spacing: 4px;
        }
      }

      .submitBtn {
        margin: 8px 0 0 0;
      }
    }
  }
}

.qrScannerDialog {
  .wrapper {
    width: 100%;
    height: 100%;
    background-color: #000000;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    position: relative;

    .backgroundVideo {
      width: 100%;
      height: 100%;
    }

    .closeBtn {
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 1;
    }

    .flashBtn {
      position: absolute;
      left: 50%;
      bottom: 32px;
      transform: translateX(-50%);
      z-index: 1;
    }
  }
}
</style>
